import python from "../assets/icons/Python.png";
import pandas from "../assets/icons/pandas.png";
import numpy from "../assets/icons/numpy.png";
import Tensorflow from "../assets/icons/tensorflow.png";
import pytorch from "../assets/icons/pytorch.png";
import r from "../assets/icons/R.png";
import aws from "../assets/icons/AWS.png";
import azure from "../assets/icons/azure.png";
import cpp from "../assets/icons/C++ (CPlusPlus).png";
import ai_ml from "../assets/icons/chip.png";
import gcp from "../assets/icons/Google Cloud.png";
import oci from "../assets/icons/Oracle.png";
import spark from "../assets/icons/Apache Spark.png";
import powerbi from "../assets/icons/power-bi.png";
import tableau from "../assets/icons/tableau.png";
import matlab from "../assets/icons/MATLAB.png";
import java from "../assets/icons/Java.png";
import javascript from "../assets/icons/JavaScript.png";
import go from "../assets/icons/Go.png";
import soft from "../assets/icons/soft.png";
import interviewprep from "../assets/icons/interviewprep.png";
import communication from "../assets/icons/communication.png";
import heroku from "../assets/icons/heroku.png";
import terraform from "../assets/icons/terraform.png";
import servicenow from "../assets/icons/servicenow.png";
import salesforce from "../assets/icons/salesforce.png";

import register from "../assets/icons/customer.png";
import certified from "../assets/icons/certified.png";
import locations from "../assets/icons/location.png";
import placed from "../assets/icons/portfolio.png";

import academics from "../assets/icons/academics.png";
import aptitude from "../assets/icons/aptitude.png";
import assessments from "../assets/icons/assessment.png";
import certificate from "../assets/icons/certificate.png";
import personality from "../assets/icons/personality.png";


import infosys from "../assets/icons/companies/infosys.png";
import tcs from "../assets/icons/companies/tcs.png";
import ibm from "../assets/icons/companies/ibm.png";
import zoho from "../assets/icons/companies/zoho.png";

import apple from "../assets/icons/companies/apple.png";
import google from "../assets/icons/companies/google.png";
import microsoft from "../assets/icons/companies/microsoft.png";
import amazon from "../assets/icons/companies/amazon.png";
import meta from "../assets/icons/companies/meta.png";

import dell from "../assets/icons/companies/dell.png";
import deloitte from "../assets/icons/companies/deloitte.png";
import techm from "../assets/icons/companies/techm.png";
import mindtree from "../assets/icons/companies/mindtree.png";

import accenture from "../assets/icons/companies/accenture.png";
import cognizant from "../assets/icons/companies/cognizant.jpeg";
import hcl from "../assets/icons/companies/hcl.png";
import wipro from "../assets/icons/companies/wipro.png";

import facebook from "../assets/icons/social/facebook.png";
import instagram from "../assets/icons/social/instagram.png";
import linkedin from "../assets/icons/social/linkedin.png";
import gmail from "../assets/icons/social/gmail.png";

import i5tech from "../assets/images/i5tech.png";
import cloud5 from "../assets/images/cloud5.png";
import cloudserv from "../assets/images/cloudserv.png";

import dot from "../assets/icons/dot.png";
import slideshow from "../assets/icons/slideshow.png";
import scroll from "../assets/icons/scroll.png";
import design from "../assets/icons/shape.png";
import leftbracket from '../assets/icons/leftbracket.png';
import approved from '../assets/icons/approved.png';
import skillcheck from '../assets/icons/gauge.png';
import benefits from '../assets/icons/benefits.png';
import attributes from '../assets/icons/attributes.png';
import fullscreen from '../assets/icons/preview.png';
import { icon_sizes } from "../Base";


const icon_style = "w-8 h-8";
export const PythonIcon = <img className={''} src={python} alt="python icon" />;
export const PandasIcon = <img className={''} src={pandas} alt="Pandas icon" />;
export const NumpyIcon = <img className={''} src={numpy} alt="Numpy icon" />;
export const TensorflowIcon = <img className={''} src={Tensorflow} alt="Tensorflow icon" />;
export const PytorchIcon = <img className={''} src={pytorch} alt="Pytorch icon" />;

export const AwsIcon = <img className={''} src={aws} alt="AWS icon" />;
export const gcpIcon = <img className={''} src={gcp} alt="GCP icon" />;
export const azureIcon = <img className={''} src={azure} alt="Azure icon" />;
export const OracleIcon = <img className={''} src={oci} alt="Oracle icon" />;
export const PowerBiIcon = <img className={''} src={powerbi} alt="Powerbi icon" />;
export const TableauIcon = <img className={''} src={tableau} alt="Tableau icon" />;
export const HerokuIcon = <img className={''} src={heroku} alt="Heroku icon" />;
export const TerraformIcon = <img className={''} src={terraform} alt="Terraform icon" />;
export const ServicenowIcon = <img className={''} src={servicenow} alt="Servicenow icon" />;
export const SalesforceIcon = <img className={''} src={salesforce} alt="Salesforce icon" />;

export const RstudioIcon = <img className={''} src={r} alt="R icon" />;
export const cppIcon = <img className={''} src={cpp} alt="CPP icon" />;
export const ai_mlIcon = <img className={''} src={ai_ml} alt="AI/ML icon" />;
export const SparkIcon = <img className={''} src={spark} alt="Spark icon" />;
export const MatlabIcon = <img className={''} src={matlab} alt="Maitalb icon" />;
export const JavaIcon = <img className={''} src={java} alt="Java icon" />;
export const JavascriptIcon = <img className={''} src={javascript} alt="Javascript icon" />;
export const GoIcon = <img className={''} src={go} alt="Go icon" />;
export const SoftIcon = <img className={''} src={soft} alt="Soft icon" />;
export const CommunicationIcon = <img className={''} src={interviewprep} alt="Interview prep icon" />;
export const InterviewIcon = <img className={''} src={communication} alt="Communication icon" />;


export const RegistedIcon = <img className={''} src={register} alt="Registed icon" />;
export const CertifiedIcon = <img className={''} src={certified} alt="Certified icon" />;
export const LocationsIcon = <img className={''} src={locations} alt="Locations icon" />;
export const PlacedIcon = <img className={''} src={placed} alt="Placed icon" />;

export const AcademicsIcon = <img className={''} src={academics} alt="Academics Icon" />;
export const AptitudeIcon = <img className={''} src={aptitude} alt="Placed icon" />;
export const AssessmentIcon = <img className={''} src={assessments} alt="Placed icon" />;
export const CertificateIcon = <img className={''} src={certificate} alt="Placed icon" />;
export const PersonalityIcon = <img className={''} src={personality} alt="Placed icon" />;

export const InfosysIcon = <img className={`${icon_sizes.medium}`} src={infosys} alt="Communication icon" />;
export const TCSIcon = <img className={`${icon_sizes.medium}`} src={tcs} alt="TCS icon" />;
export const IBMIcon = <img className={`${icon_sizes.medium}`} src={ibm} alt="IBM icon" />;

export const AppleIcon = <img className={`${icon_sizes.medium}`} src={apple} alt="Apple icon" />;
export const GoogleIcon = <img className={`${icon_sizes.medium}`} src={google} alt="Google icon" />;
export const MetaIcon = <img className={`${icon_sizes.medium}`} src={meta} alt="Meta icon" />;
export const AmazonIcon = <img className={`${icon_sizes.medium}`} src={amazon} alt="Amazon icon" />;
export const MicrosoftIcon = <img className={`${icon_sizes.medium}`} src={microsoft} alt="Microsoft icon" />;

export const DellIcon = <img className={`${icon_sizes.medium}`} src={dell} alt="Dell icon" />;
export const DeloitteIcon = <img className={`${icon_sizes.medium}`} src={deloitte} alt="Deloitte icon" />;
export const TechMIcon = <img className={`${icon_sizes.medium}`} src={techm} alt="Tech M icon" />;
export const MindtreeIcon = <img className={`${icon_sizes.medium}`} src={mindtree} alt="LTI Mindtree icon" />;

export const WiproIcon = <img className={`${icon_sizes.medium}`} src={wipro} alt="Wipro icon" />;
export const HCLIcon = <img className={`${icon_sizes.medium}`} src={hcl} alt="HCL icon" />;
export const CognizantIcon = <img className={`${icon_sizes.medium}`} src={cognizant} alt="Cognizant icon" />;
export const AccentureIcon = <img className={`${icon_sizes.medium}`} src={accenture} alt="Accenture icon" />;

export const InstagramIcon = <img className={icon_style} src={instagram} alt="Instagram icon" />;
export const FacebookIcon = <img className={icon_style} src={facebook} alt="Facebook icon" />;
export const LinkedinIcon = <img className={icon_style} src={linkedin} alt="Linkedin icon" />;
export const GmailIcon = <img className={icon_style} src={gmail} alt="Gmail icon" />;

export const I5techIcon = <img className={''} src={i5tech} alt="I5tech icon" />;
export const Cloud5Icon = <img className={''} src={cloud5} alt="cloud5 icon" />;
export const CloudservIcon = <img className={''} src={cloudserv} alt="cloudserv icon" />;

export const DotIcon = <img className={`${icon_sizes.small}`} src={dot} alt="Dot icon" />;
export const ScrollIcon = <img className={''} src={scroll} alt="Scroll icon" />;
export const SlideshowIcon = <img className={''} src={slideshow} alt="SlideShow icon" />;
export const DesignIcon = <img className={''} src={design} alt="Design icon" />;
export const LeftBracketIcon = <img className={'h-40 w-10'} src={leftbracket} alt="LeftOpen icon" />;
export const approvedIcon = <img className={`${icon_sizes.small}`} src={approved} alt="Approved icon" />;
export const SkillcheckIcon = <img className={''} src={skillcheck} alt="Skill Check icon" />;
export const BenefitsIcon = <img className={''} src={benefits} alt="Benefits icon" />;
export const AttributesIcon = <img className={''} src={attributes} alt="Attributes icon" />;
export const FullscreenIcon = <img className={''} src={fullscreen} alt="Fullscreen icon" />;