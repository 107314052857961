export const main_headings = {
  slide1: {
    id: 1,
    text: "introducing regional skills development program",
  },
  slide2: {
    id: 2,
    text: "regions to benefit from the program",
  },
  slide3: {
    id: 3,
    text: "encompassing various technologies",
  },
  slide4: {
    id: 4,
    text: "value added certifications",
  },
  slide5: {
    id: 5,
    text: "comprehensive personality development program",
  },
  slide6: {
    id: 6,
    text: "about our skill check score",
  },
  slide7: {
    id: 7,
    text: "potential program execution partners",
  },
  slide8: {
    id: 8,
    text: "deliverable updates",
  },
  slide9: {
    id: 9,
    text: "score check simulation",
  },
  slide10: {
    id: 10,
    text: "about me & our companies",
  },
};

export const purposes = [
  {
    id: 1,
    title: "Adapting to Technological Changes",
    content: [
      {
        id: 1,
        title: "Digital Literacy",
        text: "Enhancing digital skills can open up new opportunities and improve efficiency and productivity in various roles.",
      },
      {
        id: 2,
        title: "Rapid Technological Advancements",
        text: "The pace of technological change is accelerating, and staying current with new tools, software, and technologies is crucial for remaining competitive in the job market.",
      },
    ],
  },
  {
    id: 2,
    title: "Meeting Industry Requirements",
    content: [
      {
        id: 1,
        title: "Evolving Job Market",
        text: "The requirements of industries are constantly evolving. Continuous skill development ensures that professionals remain relevant and capable of meeting these changing demands.",
      },
      {
        id: 2,
        title: "Specialization",
        text: "Many industries require specialized skills that may not have been covered in depth during undergraduate studies. Post-graduation training allows for the acquisition of these niche skills.",
      },
    ],
  },
  {
    id: 3,
    title: "Enhancing Employabilitys",
    content: [
      {
        id: 1,
        title: "Competitive Edge",
        text: "Additional skills can provide a competitive advantage over other candidates in the job market.",
      },
      {
        id: 2,
        title: "Versatility",
        text: "A broader skill set allows for greater versatility and the ability to pivot to different roles or industries if needed.",
      },
    ],
  },
  {
    id: 4,
    title: "Career Advancement",
    content: [
      {
        id: 1,
        title: "Promotion Opportunities",
        text: "Advanced skills can lead to promotions and leadership positions within a company.",
      },
      {
        id: 2,
        title: "Higher Salaries",
        text: "Specialized skills and expertise often correlate with higher earning potential.",
      },
    ],
  },
  {
    id: 5,
    title: "Personal Development",
    content: [
      {
        id: 1,
        title: "Confidence",
        text: "Gaining new skills can boost confidence and self-esteem, contributing to overall job satisfaction and performance.",
      },
      {
        id: 2,
        title: " Lifelong Learning",
        text: "Developing a habit of continuous learning fosters intellectual growth and keeps the mind engaged and sharp.",
      },
    ],
  },
  {
    id: 6,
    title: "Networking Opportunities",
    content: [
      {
        id: 1,
        title: "Mentorship",
        text: "Learning environments can facilitate connections with mentors who can offer guidance and support in career development.",
      },
      {
        id: 2,
        title: "Professional Networks",
        text: "Engaging in skill development courses or certifications often provides opportunities to network with professionals in the field.",
      },
    ],
  },
  {
    id: 7,
    title: "Adaptability and Problem-Solving",
    content: [
      {
        id: 1,
        title: "Adaptability",
        text: "New skills can enhance adaptability, allowing professionals to navigate changes and unexpected challenges more effectively.",
      },
      {
        id: 2,
        title: "Problem-Solving",
        text: "Advanced training can improve problem-solving abilities by providing new tools and methodologies for tackling complex issues.",
      },
    ],
  },
  {
    id: 8,
    title: "Global Competitiveness",
    content: [
      {
        id: 1,
        title: "Global Standards",
        text: "Skill development ensures that professionals can meet global standards and compete in an increasingly interconnected world.",
      },
    ],
  },
];
